import React from "react";
import adminLayout from "../hoc/adminLayout";
import Api from '../util/Api'
import withRouter from '../util/withRouter'
import SunEditor from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import ModalComponent from "../components/ModalComponent";
// import { useNavigate } from 'react-router-dom';


class Edit extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            userId : localStorage.getItem('userId'),
            role : localStorage.getItem('role'),
            id : props.router.params.id,
            category : props.router.params.cat,
            title : "",
            titleUrl: "",
            content : "",
            imageUrl : "",
            twit : false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this)
        this.handleImageUrlChange = this.handleImageUrlChange.bind(this)
        this.getData();
    }

    handleSubmit(event) {
        event.preventDefault();
        this.postData();
    }

    handleTitleChange(event) {
        event.preventDefault();
        this.setState({
            title: event.target.value,
            titleUrl: event.target.value.replace(/[-\s]+/g, "-").replace(/^-/, '').replace(/[^a-zA-Z0-9\\-]+/g, "").toLowerCase()
        },() => {});
    }

    handleImageUrlChange(event) {
        event.preventDefault();
        this.setState({
            imageUrl: event.target.value
        },() => {});
    }

    handlingPage(status){
        if(status === 1 || status === -1){
            if(this.state.role === "0"){
                alert("Not Allowed!")
                window.location = '/list/'+this.state.category;
            }
        }
    }

    getData(){
        let uri = "article/"+this.state.category+"/detail/id";
        const postData = this.state;
        Api(uri, postData).then(res => {
            this.setState({
                title: res.title,
                titleUrl: res.titleUrl,
                imageUrl: res.imageUrl,
                content: res.content
            },() => {this.handlingPage(res.status)});
        })
        .catch(err => console.log(err));
    }

    postData(){
        let uri = "/article/"+this.state.category+"/update";
        const postData = this.state;
        
        Api(uri, postData).then(res => {
            if(res.ec === 0){
                this.setState({
                    response: res
                })
                alert('You have update an article');
                window.location = '/list/'+this.state.category;
            }else{
                alert(res.msg);
            }
        })
        .catch(err => console.log(err));
    }

    render(){
        let state = this.state;
        let categoryName = state.category.charAt(0).toUpperCase()+state.category.slice(1);
        
        const title = state.role !== "0" ? 
        // (<>admin</>):(<>bukan admin</>);
        (<input type="text"  value = {state.title} name="title" className="form-control" onChange = {this.handleTitleChange} id="inputTitle" aria-describedby="titleHelp"/>):
        (<input type="text"  value = {state.title} name="title" disabled className="form-control" onChange = {this.handleTitleChange} id="inputTitle" aria-describedby="titleHelp"/>);
        return (
            <>
                <div className="row">
                    <div className="col">
                        <h5 className="pb-2 mb-0">Update {categoryName} Article</h5>
                    </div>
                </div>
                <div className="my-3 p-3 bg-body rounded shadow-sm">
                    <h6 className="border-bottom pb-2 mb-0">Forms</h6>
                    <section id="forms">
                        <article className="my-3" id="overview">
                            <div className="bd-example">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="inputTitleUrl" className="form-label">Url</label>
                                        <input type="text" value = {state.titleUrl} readOnly  name="titleUrl" className="form-control" id="inputTitleUrl" aria-describedby="titleUrlHelp"/>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="inputTitle" className="form-label">Title</label>
                                        {title}
                                        <div id="titleHelp" className="form-text">Title of the article length 10 - 100</div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="imageUrl" className="form-label">Image URL</label>
                                        <input type="text"  value = {state.imageUrl}  name="imageUrl" className="form-control" onChange = {this.handleImageUrlChange} id="imageUrl" aria-describedby="imageUrlHelp" placeholder={"Upload gambar di halaman Media->Files lalu copy link untuk isi disini"}/>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Content</label>
                                        <SunEditor 
                                            onChange={ (data ) => {
                                                // console.log(e)
                                                this.setState({
                                                    content: data
                                                },() => {})
                                            }}
                                            setContents={state.content}
                                            setOptions={{
                                                buttonList: [
                                                    ["font", "fontSize", "formatBlock"],
                                                    [
                                                        "bold",
                                                        "underline",
                                                        "italic",
                                                        "strike",
                                                        "subscript",
                                                        "superscript",
                                                    ],
                                                    ["align", "horizontalRule", "list", "table"],
                                                    ["fontColor", "hiliteColor"],
                                                    ["outdent", "indent"],
                                                    ["undo", "redo"],
                                                    ["removeFormat"],
                                                    ["link", "image", "video"],
                                                    ["preview"],
                                                    ["fullScreen", "showBlocks", "codeView"],
                                                ],
                                            }}
                                        height="50vh"/>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </form>
                            </div>
                        </article>                  
                    </section>
                </div>
            </>
          );
    }
}

export default adminLayout(withRouter(Edit));