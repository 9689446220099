import React from "react";
import adminLayout from "../hoc/adminLayout";
import Api from '../util/Api'
import withRouter from '../util/withRouter'

import SunEditor from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';

// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import ModalComponent from "../components/ModalComponent";
// import { useNavigate } from 'react-router-dom';


class Create extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            response: {},
            userId : localStorage.getItem('userId'),
            category : props.router.params.cat,
            title : "",
            titleUrl : "",
            content : "",
            imageUrl : "",
            twit : false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this)
        this.handleImageUrlChange = this.handleImageUrlChange.bind(this)
        this.handleTwitChange = this.handleTwitChange.bind(this)
    }

    handleSubmit(event) {
        event.preventDefault();
        this.postData();
    }

    handleTitleChange(event) {
        event.preventDefault();
        this.setState({
            title: event.target.value,
            titleUrl: event.target.value.replace(/[-\s]+/g, "-").replace(/^-/, '').replace(/[^a-zA-Z0-9\\-]+/g, "").toLowerCase()
        },() => {});
    }

    handleImageUrlChange(event) {
        event.preventDefault();
        this.setState({
            imageUrl: event.target.value
        },() => {});
    }

    postData(){
        let uri = "/article/"+this.state.category+"/create";
        const postData = this.state;
        
        Api(uri, postData).then(res => {
            if(res.ec === 0){
                this.setState({
                    response: res
                })
                alert('You have submitted an article');
                window.location = '/list/'+this.state.category;
            }else{
                alert(res.msg);
            }
        })
        .catch(err => console.log(err));
    }

    handleTwitChange(event) {
        let isChecked = event.target.checked;

        this.setState({
            twit: isChecked
        })
    }
    // handleChange = (data) => {
    //     console.log(data)
        // this.setState({
        //     content: data
        // },() => {});
    // };
    
    

    render(){
        let state = this.state;
        let categoryName = state.category.charAt(0).toUpperCase()+state.category.slice(1);
        
        return (
            <>
                <div className="row">
                    <div className="col">
                        <h5 className="pb-2 mb-0">Create {categoryName} Article</h5>
                    </div>
                </div>
                <div className="my-3 p-3 bg-body rounded shadow-sm">
                    <h6 className="border-bottom pb-2 mb-0">Forms</h6>
                    <section id="forms">
                        <article className="my-3" id="overview">
                            <div className="bd-example">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="inputTitleUrl" className="form-label">Url</label>
                                        <input type="text" value = {state.titleUrl} readOnly  name="titleUrl" className="form-control" id="inputTitleUrl" aria-describedby="titleUrlHelp"/>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="inputTitle" className="form-label">Title</label>
                                        <input type="text" value = {state.title}  name="title" className="form-control" onChange = {this.handleTitleChange} id="inputTitle" aria-describedby="titleHelp" placeholder={"Title tidak bisa di ganti lagi nanti"} required/>
                                        <div id="titleHelp" className="form-text">Title of the article length 10 - 100</div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="imageUrl" className="form-label">Image URL</label>
                                        <input type="text" value = {state.value}  name="imageUrl" className="form-control" onChange = {this.handleImageUrlChange} id="imageUrl" aria-describedby="imageUrlHelp" placeholder={"Upload gambar di halaman Media->Files lalu copy link untuk isi disini"}/>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Content</label>
                                        <SunEditor 
                                            onChange={ (data ) => {
                                                // console.log(e)
                                                this.setState({
                                                    content: data
                                                },() => {})
                                            }}
                                            setOptions={{
                                                buttonList: [
                                                    ["font", "fontSize", "formatBlock"],
                                                    [
                                                        "bold",
                                                        "underline",
                                                        "italic",
                                                        "strike",
                                                        "subscript",
                                                        "superscript",
                                                    ],
                                                    ["align", "horizontalRule", "list", "table"],
                                                    ["fontColor", "hiliteColor"],
                                                    ["outdent", "indent"],
                                                    ["undo", "redo"],
                                                    ["removeFormat"],
                                                    ["link", "image", "video"],
                                                    ["preview"],
                                                    ["fullScreen", "showBlocks", "codeView"],
                                                ],
                                            }}
                                        height="60vh"/>
                                        {/* <CKEditor
                                            editor={ ClassicEditor }
                                            // data="<p>Hello from CKEditor 5!</p>"
                                            onReady={ editor => {
                                                // You can store the "editor" and use when it is needed.
                                                // console.log( 'Editor is ready to use!', editor );
                                                editor.editing.view.change((writer) => {
                                                    writer.setStyle(
                                                        "height",
                                                        "250px",
                                                        editor.editing.view.document.getRoot()
                                                    );
                                                });
                                            } }
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                this.setState({
                                                    content: data
                                                })
                                                // console.log( state.editor );
                                            } }
                                            onBlur={ ( event, editor ) => {
                                                // console.log( 'Blur.', editor );
                                            } }
                                            onFocus={ ( event, editor ) => {
                                                // console.log( 'Focus.', editor );
                                            } }
                                        /> */}
                                    </div>
                                    <div className="mb-3 form-check">
                                        <input type="checkbox" className="form-check-input" id="twitter" onChange={this.handleTwitChange}/>
                                        <label className="form-check-label" htmlFor="twitter" >Post To Twitter</label>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </form>
                            </div>
                        </article>                  
                    </section>
                </div>
            </>
          );
    }
}

export default adminLayout(withRouter(Create));