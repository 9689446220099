import React from "react";
import "../../assets/css/profile.css"
import userProfileLayout from "../../hoc/userProfileLayout";
import Api from "../../util/Api";

class ProfilePage extends React.Component {
    constructor(props){
        super(props);

        this.state = {}
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(e) {
        e.preventDefault();
        var user = localStorage.getItem("username");
        var pass = this.pass.value;
        var npass = this.npass.value;
        var cnpass = this.cnpass.value;

        let uri = "user/change-password";
        const postData = {
            username: user,
            password: pass,
            newPassword: npass,
            confirmNewPassword: cnpass
        };
        
        Api(uri, postData).then(res => {
            alert(res.msg)
            if(res.ec === 0){
                window.location.reload();
            }
        })
        .catch(err => console.log(err));
    }

    render(){
        return <>
                <div className="my-3 p-3 bg-body rounded shadow-sm">
                    <h6 className="border-bottom pb-2 mb-0 mb-3">Change Password</h6>

                    <div className="row">
                        <div className="col-4">
                            <p>Your Password must contain.</p>
                            <p> <i className="fa fa-check text-success"></i> At least 8 characters.</p>
                            <p> <i className="fa fa-check text-success"></i> At least 1 number.</p>
                            <p> <i className="fa fa-check text-success"></i> At least 1 capitalize character.</p>
                        </div>
                        <div className="col-8">
                            <form onSubmit={this.onSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputCurrent" className="form-label">Current Password</label>
                                    <div className="input-group mb-3">
                                        <input type="password" className="form-control" placeholder="Current Password" ref={(c) => this.pass = c} id="pass" name="pass" aria-label="" aria-describedby="basic-addon2"/>
                                        <span className="input-group-text" id="basic-addon2"><i className="fa fa-key"></i></span>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputNew" className="form-label">New Password</label>
                                    <div className="input-group mb-3">
                                        <input type="password" className="form-control" placeholder="New Password" ref={(c) => this.npass = c} id="npass" name="npass" aria-label="" aria-describedby="basic-addon2"/>
                                        <span className="input-group-text" id="basic-addon2"><i className="fa fa-key"></i></span>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputConfirmNew" className="form-label">Confirm New Password</label>
                                    <div className="input-group mb-3">
                                        <input type="password" className="form-control" placeholder="Confirm New Password" ref={(c) => this.cnpass = c} id="cnpass" name="cnpass" aria-label="" aria-describedby="basic-addon2"/>
                                        <span className="input-group-text" id="basic-addon2"><i className="fa fa-key"></i></span>
                                    </div>
                                </div>
                                <hr/>
                                <button type="submit" className="btn btn-default">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            
        </>
    }
}

export default userProfileLayout(ProfilePage);