// import 'font-awesome/css/font-awesome.min.css';
import './assets/css/app.css';
import DashboardPage from './pages/DashboardPage';
// import TypographyPage from './pages/TypographyPage'
import LoginPage from './pages/auth/LoginPage'
import ResetPassword from './pages/auth/ResetPassword';
import ProfilePage from './pages/profile/ProfilePage';
import ChangePasswordPage from './pages/profile/ChangePasswordPage';
import UserPreferencesPage from './pages/profile/UserPreferencesPage'
import AdminBlankPage from './pages/AdminBlankPage';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Create from './pages/Create';
import List from './pages/List';
import Edit from './pages/Edit';
import Preview from './pages/Preview';
import Upload from './pages/Upload';
import Files from './pages/Files';
import Admin from './pages/Admin';
import TypographyPage from './pages/TypographyPage';
import Message from './pages/Message';
// import TypographyPage from './pages/TypographyPage';

function App() {
    return (
      <Router>
          <Routes>
              <Route exact path='/' element={<DashboardPage/>} />
              <Route exact path='/login' element={<LoginPage/>} />
              <Route exact path='/reset-password' element={<ResetPassword/>} />
              <Route exact path='/profile' element={<ProfilePage/>} />
              <Route exact path='/change-password' element={<ChangePasswordPage/>} />
              <Route exact path='/preferences' element={<UserPreferencesPage/>} />
              <Route exact path='/typography' element={<TypographyPage/>} />
              <Route exact path='/blank-page' element={<AdminBlankPage/>} />
              <Route exact path='/upload' element={<Upload/>} />
              <Route exact path='/files' element={<Files/>} />
              <Route exact path='/admin' element={<Admin/>} />
              <Route exact path='/message' element={<Message/>} />

              <Route path="/create" render={""}>
                <Route path='' element={<DashboardPage/>}/>
                <Route path='index' element={<DashboardPage/>}/>
                <Route path=':cat' element={<Create/>}/>
              </Route>
              <Route path="/list">
                <Route path='' element={<DashboardPage/>}/>
                <Route path='index' element={<DashboardPage/>}/>
                <Route path=':cat' element={<List/>}/>
              </Route>
              <Route path="/edit">
                <Route path='' element={<DashboardPage/>}/>
                <Route path='index' element={<DashboardPage/>}/>
                <Route path=':cat/:id' element={<Edit/>}/>
              </Route>
              <Route path="/preview">
                <Route path='' element={<DashboardPage/>}/>
                <Route path='index' element={<DashboardPage/>}/>
                <Route path=':cat/:id' element={<Preview/>}/>
              </Route>
          </Routes>  
      </Router>
    )
}

export default App;
