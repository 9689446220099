import React from "react";
import adminLayout from "../hoc/adminLayout";
import { Link } from "react-router-dom";
import Api from "../util/Api";
import dateFormat from "dateformat";
import withRouter from "../util/withRouter";

class List extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            response: [],
            userId: localStorage.getItem('userId'),
            category : props.router.params.cat,
            limit: 25,
            offset: 0,
            role: localStorage.getItem('role'),
            status: 2
        };
        this.nextData = this.nextData.bind(this);
        this.prevData = this.prevData.bind(this);
        this.getNick = this.getNick.bind(this);
        this.renderStatus = this.renderStatus.bind(this);

        this.getData(this.state.offset);
    }

    getData(newOffset){
        let uri = "article/"+this.state.category+"/list";
        const postData = {
            userId: this.state.userId,
            category : this.state.category,
            limit: this.state.limit,
            offset: newOffset,
            status: this.state.status
        };
        
        Api(uri, postData).then(res => {
            if(res.object.length > 0){
                this.setState({
                    response: res.object,
                    offset: newOffset
                },() => {});
            }else{
                if(newOffset === this.state.offset){
                    this.setState({
                        response: res.object
                    },() => {});
                }
            }
        })
        .catch(err => console.log(err));
    }

    publish(title){
        let uri = "article/"+this.state.category+"/publish";
        const postData = {
            userId: this.state.userId,
            category : this.state.category,
            titleUrl: title
        };
        
        Api(uri, postData).then(res => {
            if(res.ec === 0){
                alert('Article Published');
                window.location = '/list/'+this.state.category;
            }else{
                alert(res.msg);
            }
        })
        .catch(err => console.log(err));
    }

    reject(title){
        let uri = "article/"+this.state.category+"/reject";
        const postData = {
            userId: this.state.userId,
            category : this.state.category,
            titleUrl: title
        };
        
        Api(uri, postData).then(res => {
            if(res.ec === 0){
                alert('Article Rejected');
                window.location = '/list/'+this.state.category;
            }else{
                alert(res.msg);
            }
        })
        .catch(err => console.log(err));
    }

    ready(title){
        let uri = "article/"+this.state.category+"/ready";
        const postData = {
            userId: this.state.userId,
            category : this.state.category,
            titleUrl: title
        };
        
        Api(uri, postData).then(res => {
            if(res.ec === 0){
                alert('Article is Ready, Wait for Approval');
                window.location = '/list/'+this.state.category;
            }else{
                alert(res.msg);
            }
        })
        .catch(err => console.log(err));
    }

    nextData(){
        let offset = this.state.offset+this.state.response.length;
        if(this.state.response.length === this.state.limit){
            this.getData(offset);
        }
    }

    prevData(){
        let offset = this.state.offset-this.state.limit;
        if(offset >= 0){
            this.getData(offset);
        }
    }
    
    renderStatus(status){
        switch (status) {
            case 0:
            default:
                return <span className=""><strong>Draft</strong></span>;
            case 1:
                return <span className="text-success"><strong>Published</strong></span>;
            case 2:
                return <span className="text-warning"><strong>UnPublished</strong></span>;
            case -1:
                return <span className="text-danger"><strong>Rejected</strong></span>;
        }
    }

    handleGetNick = (e) => {
        e.preventDefault();
        let key = e.target.getAttribute("data-index");
        if(e.target.innerHTML === key){
            this.getNick(e);   
        }
    };

    getNick(e){
        let uri = "user/nick";
        const postData = {
            id: e.target.innerHTML
        };
        
        Api(uri, postData).then(res => {
            e.target.innerHTML = res;
        })
        .catch(err => console.log(err));
        
    }

    search = (e) => {
        e.preventDefault();
        let uri = "article/search";
        const postData = {
            userId: this.state.userId,
            category: this.state.category,
            title: this.input.value,
            limit: 20,
            offset: 0,
            status: this.state.status
        };
        
        Api(uri, postData).then(res => {
            if(res.ec === 0){
                this.setState({
                    response: res.object
                },() => {});
            }else{
                if(this.input.value.length === 0){
                    this.getData(this.state.offset);
                }else{
                    alert(res.msg);
                }
            }
        })
        .catch(err => console.log(err));
    }

    handleChangeView = (e) => {
        if(e.target.value !== undefined){
            this.setState({
                status: e.target.value
            },() => {this.getData(this.state.offset)});
        }
    }

    render(){
        let state = this.state;
        // let categoryName = state.category.charAt(0).toUpperCase()+state.category.slice(1);
        const data = state.response.length > 0 ? (
            <>
            {state.response.map((res,i) =>  (
                <tr key={i}>
                    <td>
                        <div className="dropdown table-action-dropdown">
                            <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButtonSM" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v" aria-hidden="true"></i></button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtonSM">
                                <li>
                                    <Link to={"/preview/"+state.category+"/"+res.id} className="dropdown-item text-primary">
                                        <i className="fa fa-eye" aria-hidden="true"></i>&nbsp;Preview
                                    </Link>
                                </li>
                                {
                                    state.role !== "0" ?
                                    (
                                        <>
                                            {
                                            res.status !== -1 ?
                                                (
                                                    <>
                                                        <div className="dropdown-divider"></div>
                                                        <li>
                                                            <Link to={"/edit/"+state.category+"/"+res.id} className="dropdown-item text-dark">
                                                                <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Edit
                                                            </Link>
                                                        </li>
                                                    </>
                                                ):
                                                (<></>)
                                            }
                                            {
                                                res.status === 2 ?
                                                (
                                                    <>
                                                        <div className="dropdown-divider"></div>
                                                        <li>
                                                            <span className="btn dropdown-item text-success" onClick={() => this.publish(res.titleUrl)}>
                                                                <i className="fa fa-check" aria-hidden="true"></i>
                                                                &nbsp;Approve
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="btn dropdown-item text-danger" onClick={() => this.reject(res.titleUrl)}>
                                                                <i className="fa fa-times" aria-hidden="true"></i>
                                                                &nbsp;Reject
                                                            </span>
                                                        </li>
                                                    </>
                                                ):
                                                (
                                                <>
                                                {
                                                    res.status === 0 ?
                                                    (
                                                        <>
                                                            <div className="dropdown-divider"></div>
                                                            <li>
                                                                <span className="btn dropdown-item text-success" onClick={() => this.ready(res.titleUrl)}>
                                                                    <i className="fa fa-check" aria-hidden="true"></i>
                                                                    &nbsp;Ready
                                                                </span>
                                                            </li>
                                                        </>
                                                    ):
                                                    (<></>)
                                                }
                                                </>
                                                )
                                            }
                                        </>
                                    ):
                                    (
                                    <>
                                        {
                                            res.status === 0 || res.status === 2 ?
                                            (
                                                <>
                                                    <div className="dropdown-divider"></div>
                                                    <li>
                                                        <Link to={"/edit/"+state.category+"/"+res.id} className="dropdown-item text-dark">
                                                            <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Edit
                                                        </Link>
                                                    </li>
                                                    {
                                                        res.status === 0 ? 
                                                        (
                                                            <>
                                                                <div className="dropdown-divider"></div>
                                                                <li>
                                                                    <span className="btn dropdown-item text-success" onClick={() => this.ready(res.titleUrl)}>
                                                                        <i className="fa fa-check" aria-hidden="true"></i>
                                                                        &nbsp;Ready
                                                                    </span>
                                                                </li>
                                                            </>
                                                        ):(<></>)
                                                    }
                                                </>
                                            ):
                                            (<></>)
                                        }
                                    </>
                                    )
                                }
                            </ul>
                        </div>
                    </td>
                    <td className="col-4">
                        <strong className="">{res.title}</strong>
                    </td>
                    <td>
                        <span title={res.imageUrl} dangerouslySetInnerHTML={{__html: res.imageUrl.substring(0,30)}}/>
                    </td>
                    {state.role !== "0" ? (
                        <></>
                    ):(
                        <>
                            <td>
                                <span dangerouslySetInnerHTML={{__html: res.content.substring(0,30)}}/>
                            </td>
                        </>
                    )}
                    {
                        state.role !== "0" ? 
                        (
                            <td className="btn">
                                <strong onClick={this.handleGetNick} data-index={res.userId}>{res.userId}</strong>
                            </td>
                        ):
                        (<></>)
                    }
                    <td>{dateFormat(res.createdAt, "mmm dd yyyy, HH:MM:ss")}</td>
                    <td>
                        {this.renderStatus(res.status)}
                    </td>
                </tr>
            ))}
            </>
        ):(
            <tr>
                <td colSpan={6} className="text-center text-capitalize"><h2>tidak ada data</h2></td>
            </tr>
        )

        const pagination = state.response.length > 0 ? 
        (
            <ul className="pagination">
                <li className="page-item" onClick={this.prevData}>
                    <Link className="page-link" to="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>
                    </Link>
                </li>
                <li className="page-item" onClick={this.nextData}>
                    <Link className="page-link" to="#" aria-label="Next">
                        <span className="sr-only">Next</span>
                        <span aria-hidden="true">&raquo;</span>
                    </Link>
                </li>
            </ul>
        )
        :
        (
            <ul className="pagination">
                <li className="page-item">
                <Link className="page-link" to="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                </Link>
                </li>
                {/* <li className="page-item"><a className="page-link" href="#">1</a></li> */}
                <li className="page-item">
                <Link className="page-link" to="#" aria-label="Next">
                    <span className="sr-only">Next</span>
                    <span aria-hidden="true">&raquo;</span>
                </Link>
                </li>
            </ul>
        )
        return (
            <>
                <div className="row">
                    <div className="col">
                        <h5 className="pb-2 mb-0 text-capitalize">List {state.category}</h5>
                    </div>
                    <div className="col text-right">
                        <div className="row">
                            {/* {
                                state.role !== "0" ?
                                (
                                    <div className="col-lg-11 col-md-9">
                                        <div className="db-example">
                                            <form onSubmit={this.search}>
                                                <div className="input-group mb-2">
                                                    <input type="text" className="form-control" ref={(c) => this.input = c} placeholder="Search Here.." aria-label="Search Here.." aria-describedby="basic-addon2" />
                                                    <span className="btn" id="basic-addon2" onClick={(e) => this.search(e)}><i className="fa fa-search"></i></span>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                )
                                :
                                (<div className=""></div>)
                            } */}
                            <div className="text-right mb-2">
                                <Link to={"/create/"+state.category}>
                                    <button className="btn btn-default low-height-btn">
                                        <i className="fa fa-plus"></i>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    state.role !== "0" ?
                    (
                    <div className="row my-3">
                        <div className="col-lg-7 col-xs-12">
                            <div className="db-example">
                                <form onSubmit={this.search}>
                                    <div className="input-group mb-2">
                                        <input type="text" className="form-control" id="search" ref={(c) => this.input = c} placeholder="Search Here.." aria-label="Search Here.." aria-describedby="basic-addon2" />
                                        <span className="btn" id="basic-addon2" onClick={(e) => this.search(e)}><i className="fa fa-search"></i></span>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <form className="col-lg-5 col-xs-12">
                            <select className="form-select form-select-md" id="status" defaultValue={this.state.status} onChange={(e) => (this.handleChangeView(e))}>
                                <option value={2}>UnPublished</option>
                                <option value={0}>Draft</option>
                                <option value={1}>Published</option>
                                <option value={-1}>Rejected</option>
                            </select>
                        </form>
                    </div>
                    )
                    :
                    (<></>)
                }
                {/* <div className="d-flex text-muted list-scroll"> */}
                <div className="row">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th><cite>Title</cite></th>
                                <th><cite>Image</cite></th>
                                {state.role !== "0" ? (
                                    <></>
                                ):(
                                    <>
                                        <th><cite>Content</cite></th>
                                    </>
                                )}
                                {state.role !== "0" ? (<th><cite>Author</cite></th>):(<></>)}
                                <th><cite>Date</cite></th>
                                <th><cite>Status</cite></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data}
                        </tbody>
                    </table>
                </div>
                <nav className="table-bottom-center-pagination" aria-label="Page navigation example ">
                    {pagination}
                </nav>
            </>
          );
    }
}

export default adminLayout(withRouter(List));