import axios from 'axios';

async function ApiIMG(uri, postData){
    let responseData = [];
    // let url = "http://localhost:9091/";
    let url = "https://luapan.com:9091/";
    const res = axios.post(
    url+uri,
    postData,
    {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    
    const dataPromise = res.then((response) => response.data)
    responseData = await dataPromise;
    return responseData;

    // const res = axios.post('http://localhost:9091/file/upload', formData, { headers: { 'Content-Type': 'multipart/form-data'}});
    // const dataPromise = res.then((response) => response.data)
    // responseData = dataPromise;
    // return responseData;
}

export default ApiIMG;