import React from "react";
import { Link } from "react-router-dom";
import Api from "../util/Api";

class Header extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            userId: localStorage.getItem('userId'), 
            access: JSON.parse(localStorage.getItem('access')),
            role: localStorage.getItem('role'),
            messageData: 0
        }
        this.logout = this.logout.bind(this);
        this.getMessage();
    }
    logout(){
        localStorage.removeItem("login")
        localStorage.removeItem("userId")
        localStorage.removeItem("username")
        localStorage.removeItem("role")
        localStorage.removeItem("access")
        localStorage.removeItem('nickname');
        localStorage.removeItem('email');
        window.location = '/login';
    }

    getMessage(){
        let uri = "note/count";
        const postData = {
            userIdTarget: this.state.userId,
        }

        Api(uri, postData).then(res => {
            if(res.ec === 0){
                this.setState({
                    messageData: res.object
                },() => {});
            }
        })
        .catch(err => console.log(err));
    }

    render(){
        let state = this.state;
        // const path = this.props.router;
        const access = state.access;

        let menu = null;
        if(access !== null){
            menu = access.map((res,i) =>  (
                <li key={i}><a tag="a" href={"/list/"+res.path} className={"mt-2"}>&nbsp;{res.name}</a></li>
            ));
        }
        const categoryMenu = menu !== null ? (
            <>
                <div className="dropdown-divider"></div>
                <li className="nav-item mb-1">
                    <Link tag="a" className={""} data-bs-toggle="collapse" data-bs-target="#category-collapse">
                        <i className="fa fa-list"/> Kategori
                    </Link>
                    <div className={"collapse show"} id="category-collapse">
                        <ul className="list-unstyled mt-lg-0">
                            {menu}
                        </ul>
                    </div>
                </li>
            </>
        ) : (<></>)

        const specialMenu = state.role !== "0" ?
        (
            <li className="mb-1">
                <Link tag="a" className={""} to={"/admin"}>
                    <i className="fa fa-users"></i> User Menu
                </Link>
            </li>
        ):
        (<></>);
        return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top border-bottom">
            <div className="container-fluid">
                {
                    state.role === "0" ?
                    (
                    <div className="notification">
                        <Link to={"/message"}>
                            <h1>
                                <i className="fa fa-envelope">
                                    {state.messageData > 0 ? (
                                        <>
                                            <sup className="notif-number"></sup>
                                        </>
                                    ):(<></>)}
                                </i>
                            </h1>
                        </Link>
                    </div>
                    )
                    :
                    (<></>)
                }
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                <div className="collapse navbar-collapse scrolling" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mt-2 mt-lg-0 navbar-menu">
                        <div className="">
                            <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                                <li className="nav-item mb-1">
                                    <Link className="" to={"/"}><i className="fa fa-dashboard"/> Dashboard</Link>
                                </li>
                                {specialMenu}
                                <div className="dropdown-divider"></div>
                                <li className="nav-item mb-1">
                                    <Link tag="a" className={""} to="/files">
                                        <i className="fa fa-image"/> Media
                                    </Link>
                                    {/* <div className={"collapse"} id="media-collapse">
                                        <ul className="list-unstyled mt-lg-0">
                                            <li className="mt-2">
                                                <Link tag="a" to="/files">
                                                     &nbsp;Files
                                                </Link>
                                            </li>
                                        </ul>
                                    </div> */}
                                </li>
                                {categoryMenu}
                                
                            </ul>
                        </div>
                        <li className="nav-item dropdown notifications text-right">
                            <Link className="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-user"></i></Link>
                            <div className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                <Link className="dropdown-item" to={"/profile"}>Profile</Link>
                                <div className="dropdown-divider"></div>
                                <span className="dropdown-item" onClick={this.logout}><i className="fa fa-sign-out" aria-hidden="true"/>Logout</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        )
    }
}

export default Header;