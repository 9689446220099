import React from "react";
// import adminLayout from "../hoc/adminLayout"
import ProgressBar from 'react-bootstrap/ProgressBar';
import Api from "../util/Api";
import dateFormat from "dateformat";

class Progress extends React.Component {
    constructor(props){
        super(props);

        this.state = {
          response: {},
          list: [],
          userId: props.user
        }
        this.getProgress();
        this.getList();
    }

    getProgress(){
      let uri = "article/count/progress";
      const postData = this.state;
      Api(uri, postData).then(res => {
        if(res.ec === 0){
          this.setState({
            response: res.object
          },() => {});
        }
      })
      .catch(err => console.log(err));
    }

    getList(){
      let uri = "article/count/list";
      const postData = this.state;
      Api(uri, postData).then(res => {
        if(res.ec === 0){
          this.setState({
            list: res.object
          },() => {});
        }
      })
      .catch(err => console.log(err));
    }

    render(){
        let res = this.state.response;
        let list = this.state.list;
        
        const now = res.current === undefined ? 0 : Math.floor((res.current/ res.maxArticle)*100);
        // const prize = res.prize === undefined ? 0 : res.prize;

        const data = list.length > 0 ? (
            <>
            {list.map((res,i) =>  (
                <tr key={i}>
                    <td>{res.current}</td>
                    <td>{dateFormat(res.createdAt, "mmm dd yyyy")}</td>
                    {/* <td>{res.status}</td> */}
                </tr>
            ))}
            </>
        ):(<><tr><td className="text-center" colSpan={2}>-No Data-</td></tr></>);
        var nf = new Intl.NumberFormat();
        return <>
          <div className="row">
            <div className="col-xl-12 col-sm-12 mb-3">
            <h3>Payout Sekarang Jika mencapai 100% Rp {nf.format(res.prize === undefined ? 0 : res.prize)},-.</h3>
            </div>
            <div className="col-xl-12 col-sm-12 mb-3">
              Artikel bulan ini {res.current === undefined ? 0 : res.current} [{now+"%"}]
            </div>
            <div className="col-xl-12 col-sm-12 mb-3">
              <ProgressBar now={now} label={`${now}%`} style={{height:"50px"}}/>
            </div>
          </div>
          <div className="row">
              <div className="col">
                  <h5 className="pb-2 mb-0">Last Progress</h5>
              </div>
          </div>
          <div className="d-flex text-muted list-scroll">
              <table className="table">
                  <thead>
                      <tr>
                          <th><cite>Total Article</cite></th>
                          <th><cite>Date</cite></th>
                      </tr>
                  </thead>
                  <tbody>
                      {data}
                  </tbody>
              </table>
          </div>
        </>
    }
}

export default Progress;