import React from "react";
import adminLayout from "../hoc/adminLayout";

import ModalComponent from "../components/ModalComponent";
import Api from "../util/Api";
import dateFormat from "dateformat";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Upload from "./Upload";
import { Link } from "react-router-dom";

class Files extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            response: [],
            prevResponse: [],
            userId: localStorage.getItem('userId'),
            limit: 15,
            offset: 0,
            role: localStorage.getItem('role'),
            activeItem: "",
            alert: ""
        };
        this.nextData = this.nextData.bind(this);
        this.prevData = this.prevData.bind(this);
        this.getNick = this.getNick.bind(this);
        // this.renderStatus = this.renderStatus.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.setActiveItem = this.setActiveItem.bind(this);
        this.search = this.search.bind(this);

        this.getData(this.state.offset);
    }

    getData(newOffset){
        let uri = "img/list";
        const postData = {
            userId: this.state.userId,
            limit: this.state.limit,
            offset: newOffset
        };
        
        Api(uri, postData).then(res => {
            if(res.object.length > 0){
                this.setState({
                    response: res.object,
                    offset: newOffset
                },() => {});
            }
        })
        .catch(err => console.log(err));
    }

    // publish(title){
    //     let uri = "article/"+this.state.category+"/publish";
    //     const postData = {
    //         userId: this.state.userId,
    //         category : this.state.category,
    //         title: title
    //     };
        
    //     Api(uri, postData).then(res => {
    //         if(res.ec === 0){
    //             alert('Article Published');
    //             window.location = '/list/'+this.state.category;
    //         }else{
    //             alert(res.msg);
    //         }
    //     })
    //     .catch(err => console.log(err));
    // }

    // reject(title){
    //     let uri = "article/"+this.state.category+"/reject";
    //     const postData = {
    //         userId: this.state.userId,
    //         category : this.state.category,
    //         title: title
    //     };
        
    //     Api(uri, postData).then(res => {
    //         if(res.ec === 0){
    //             alert('Article Rejected');
    //             window.location = '/list/'+this.state.category;
    //         }else{
    //             alert(res.msg);
    //         }
    //     })
    //     .catch(err => console.log(err));
    // }

    nextData(){
        let offset = this.state.offset+this.state.response.length;
        if(this.state.response.length === this.state.limit){
            this.getData(offset);
        }
    }

    prevData(){
        let offset = this.state.offset-this.state.limit;
        if(offset >= 0){
            this.getData(offset);
        }
    }
    
    // renderStatus(status){
    //     switch (status) {
    //         case 0:
    //         default:
    //             return <span className="text-warning"><strong>UnPublished</strong></span>;
    //         case 1:
    //             return <span className="text-success"><strong>Published</strong></span>;
    //         case -1:
    //             return <span className="text-danger"><strong>Rejected</strong></span>;
    //     }
    // }

    handleGetNick = (e) => {
        e.preventDefault();

        let key = e.target.getAttribute("data-index");
        if(e.target.innerHTML === key){
            this.getNick(e);
        }
    };

    getNick(e){
        let uri = "user/nick";
        const postData = {
            id: e.target.innerHTML
        };
        
        Api(uri, postData).then(res => {
            e.target.innerHTML = res;
        })
        .catch(err => console.log(err));
        
    }

    copyToClipboard(e){
        this.setState({
            alert: "show"
        },() => {});
        setTimeout(() => {
            this.setState({
                alert: ""
            },() => {});
        }, 2000);
    }

    setActiveItem = (e) => {
        this.setState({
            activeItem: e.target.currentSrc,
        },() => {});
    }
    modalContent = (e) => {
        return <>
            <img src={this.state.activeItem === "" ? "/./images/default.jpg" : this.state.activeItem} alt='' className='img-responsive img-fluid center' width={500}/>
        </>;
    }
    search = (e) => {
        e.preventDefault();
        let uri = "img/search";
        const postData = {
            name: this.input.value
        };
        
        Api(uri, postData).then(res => {
            if(res.ec === 0){
                this.setState({
                    prevResponse: this.state.response,
                    response: res.object
                },() => {});
            }
        })
        .catch(err => console.log(err));
    }

    render(){
        let state = this.state;
        let url = "https://storage.googleapis.com/barbar_buckets/";

        const data = (
            <>
            {state.response.map((res,i) =>  (
                <tr key={i}>
                    <td className="col-2">
                        <img src={url+dateFormat(res.createdAt, "yyyy-mm-dd/")+res.name} alt='' className='img-responsive img-fluid btn image-middle' width={100} data-bs-toggle="modal" data-bs-target="#exampleModalDefault" onClick={(e) => this.setActiveItem(e)}/>
                    </td>
                    <td className="col">
                        <CopyToClipboard text={url+dateFormat(res.createdAt, "yyyy-mm-dd/")+res.name}
                        onCopy={(e) => this.copyToClipboard(e)}>
                            {/* <strong className="btn" dangerouslySetInnerHTML={{__html: res.name.substring(0,50)}}/> */}
                            {/* <>
                                <div className="btn">{url}{dateFormat(res.createdAt, "yyyy-mm-dd/")}</div>
                                <div className="btn hide-long-text">{res.name}</div>
                            </> */}
                            <strong className="btn">
                                <div className="">{url}{dateFormat(res.createdAt, "yyyy-mm-dd/")}</div>
                                <div className="btn hide-long-text">{res.name}</div>
                            </strong>
                            {/* <strong className="btn hide-long-text">{url}{dateFormat(res.createdAt, "yyyy-mm-dd/")}{res.name}</strong> */}
                        </CopyToClipboard>
                    </td>
                    {
                        state.role !== "0" ? 
                        (
                            <td className="btn col">
                                <strong onClick={this.handleGetNick} data-index={res.userId}>{res.userId}</strong>
                            </td>
                        ):
                        (<></>)
                    }
                    <td className="col">{dateFormat(res.createdAt, "mmm dd yyyy, HH:MM:ss")}</td>
                </tr>
            ))}
            </>
        )

        const pagination = state.response.length > 0 ? 
        (
            <ul className="pagination">
                <li className="page-item" onClick={this.prevData}>
                    <Link className="page-link" to="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>
                    </Link>
                </li>
                <li className="page-item" onClick={this.nextData}>
                    <Link className="page-link" to="#" aria-label="Next">
                        <span className="sr-only">Next</span>
                        <span aria-hidden="true">&raquo;</span>
                    </Link>
                </li>
            </ul>
        )
        :
        (<></>
            // <ul className="pagination">
            //     <li className="page-item">
            //     <Link className="page-link" to="#" aria-label="Previous">
            //         <span aria-hidden="true">&laquo;</span>
            //         <span className="sr-only">Previous</span>
            //     </Link>
            //     </li>
            //     <li className="page-item">
            //     <Link className="page-link" to="#" aria-label="Next">
            //         <span className="sr-only">Next</span>
            //         <span aria-hidden="true">&raquo;</span>
            //     </Link>
            //     </li>
            // </ul>
        )
        return (
            <>
                <div className="row">
                    <div className="col-xs-12">
                        <Upload/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 z-index-master">
                        <div className={"alert alert-success fade text-center position-fixed "+state.alert} role="alert">
                            <strong>Copy to Clipboard.</strong>
                        </div>
                    </div>
                    <div className="col">
                        <h5 className="pb-2 mb-0">List</h5>
                    </div>
                    <div className="col text-right">
                        <div className="db-example">
                            <form onSubmit={this.search}>
                                <div className="input-group mb-2">
                                    <input type="text" className="form-control" ref={(c) => this.input = c} placeholder="Search Here.." aria-label="Search Here.." aria-describedby="basic-addon2" />
                                    <span className="btn" id="basic-addon2" onClick={(e) => this.search(e)}><i className="fa fa-search"></i></span>
                                </div>
                            </form>
                        </div>
                        {/* <Link to={"/create/"+state.category}>
                            <button className="btn btn-default low-height-btn">
                                <i className="fa fa-plus"></i>
                            </button>
                        </Link> */}
                    </div>
                </div>
                <div className="d-flex text-muted list-scroll">
                    <table className="table">
                
                        <thead>
                            <tr>
                                <th><cite>Preview</cite></th>
                                <th><cite>Link</cite></th>
                                {state.role !== "0" ? (<th><cite>Author</cite></th>):(<></>)}
                                <th><cite>Date</cite></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data}
                        </tbody>
                    </table>
                </div>
                
                <ModalComponent title="Preview Image" content={this.modalContent()} dataBsBackdrop="static" id="exampleModalDefault"/>
                <nav className="table-bottom-center-pagination" aria-label="Page navigation example ">
                    {pagination}
                </nav>
            </>
          );
    }
}

export default adminLayout(Files);