import axios from 'axios';

async function Api(uri, postData){
    let responseData = [];
    // let url = "http://localhost:9091/";
    let url = "https://luapan.com:9091/";
    const res = axios.post(
    url+uri,
    postData,
    {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'https://luapaninfo.com/',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept, X-Requested-With, privateKey'

        }
    });
    
    const dataPromise = res.then((response) => response.data)
    responseData = await dataPromise;
    return responseData;
}

export default Api;