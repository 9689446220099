import React from "react";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Link } from 'react-router-dom';
import withRouter from "../util/withRouter";
// import Api from "../util/Api";

class Sidebar extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            response: [],
            userId: localStorage.getItem('userId'),
            username: localStorage.getItem('username'),
            nickname: localStorage.getItem('nickname'),
            access: JSON.parse(localStorage.getItem('access')),
            role: localStorage.getItem('role')
        }
        this.logout = this.logout.bind(this);

        this.checkAccess();
    }
    
    handleClick = (e) => {
        e.preventDefault();
        const regExCreate = /^\/create/;
        const regExEdit = /^\/edit/;
        let path = this.props.router.location.pathname;

        if(regExCreate.test(path) || regExEdit.test(path)){    
            if(window.confirm("Kontent yang belum di simpan akan hilang. Anda yakin?")){
                this.redirectFunc(e.target.href);
            }
        }else{

            if(e.target.pathname === '/logout'){
                this.logout();
            }else{
                this.redirectFunc(e.target.href);
            }
        }
    }

    redirectFunc(target){
        if(target === undefined){
            window.location = "/";
        }else{
            window.location = target
        }
    }

    logout(){
        localStorage.removeItem("login")
        localStorage.removeItem("userId")
        localStorage.removeItem("username")
        localStorage.removeItem("role")
        localStorage.removeItem("access")
        localStorage.removeItem('nickname');
        localStorage.removeItem('email');
        window.location = '/login';
    }

    checkAccess(){
        const regExList = /^\/list/;
        const regExCreate = /^\/create/;
        const regExEdit = /^\/edit/;
        let path = this.props.router.location.pathname;
        if(regExList.test(path) || regExCreate.test(path) || regExEdit.test(path)){
            let category = this.props.router.params.cat;
            let redirect = true;
            if(category !== undefined){
                for(var i = 0;i<this.state.access.length;i++){
                    if(this.state.access[i].path === this.props.router.params.cat){
                        redirect = false;
                        break;
                    }
                }
                if(redirect){
                    alert("Halaman tidak di izinkan")
                    window.location = '/';
                    // this.logout();
                }
            }
        }
    }

    render(){
        let state = this.state;
        const path = this.props.router.location.pathname;
        const access = state.access;

        const regExList = /^\/list/;
        const regExCreate = /^\/create/;
        const regExEdit = /^\/edit/;

        const activePage = regExList.test(path) || regExCreate.test(path) || regExEdit.test(path) ? "active" : "";

        let menu = null;
        if(access !== null){
            menu = access.map((res,i) =>  (
                <li key={i}><a tag="a" href={"/list/"+res.path} className={path === "/list/"+res.path || path === "/create/"+res.path || path === "/edit/"+res.path ? "active" : ""} onClick={(e) => this.handleClick(e)}>{res.name}</a></li>
            ));
        }

        const categoryMenu = menu !== null ? (
            <>
                <li className="border-top my-3"></li>
                <li className="mb-1">
                    <Link tag="a" className={activePage} data-bs-toggle="collapse" data-bs-target="#category-collapse">
                        <i className="fa fa-list"></i> Kategori
                    </Link>
                    <div className={"collapse show"} id="category-collapse">
                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                            {menu}
                        </ul>
                    </div>
                </li> 
            </>
        ) : (<></>)

        const specialMenu = state.role !== "0" ?
        (
            <li className="mb-1">
                <Link tag="a" className={path === "/admin" ? "active" : ""} to="/admin" onClick={(e) => this.handleClick(e)}>
                    <i className="fa fa-users"></i> Users
                </Link>
            </li>
        ):
        (<></>);
        const hiddenMenu = state.role !== "0" ?
        (
            <>
                <li className="my-1"><br/></li>
                <li className="my-1"><br/></li>
                <li className="my-1"><br/></li>
                <li className="my-1"><br/></li>
                <li className="my-1"><br/></li>
            </>
        ):
        (<></>);
        return (
        <div className="border-end sidenav" id="sidebar-wrapper">
            <div className="sidebar-heading border-bottom ">
                <Link tag="a" to="/" onClick={(e) => this.handleClick(e)}>
                    {/* <img alt="Alt content" src={require('./../assets/images/logo.png')} /> */}
                    <h1>BARBARINFO</h1>
                </Link>
            </div>
            <PerfectScrollbar className="sidebar-items">
                <ul className="list-unstyled ps-0">
                    <li className="mb-1">
                        <Link tag="a" className={path === "/" ? "active" : ""} to="/" onClick={(e) => this.handleClick(e)}>
                            <i className="fa fa-dashboard"></i> Dashboard
                        </Link>
                    </li>
                    {specialMenu}
                    <li className="border-top my-3"></li>
                    <li className="mb-1">
                        <Link tag="a" className={path === "/files" ? "active" : ""} to="/files" onClick={(e) => this.handleClick(e)}>
                        {/* <Link tag="a" className={path === "/files" ? "active" : ""} data-bs-toggle="collapse" data-bs-target="#media-collapse"> */}
                            <i className="fa fa-image"></i> Media
                        </Link>
                        {/* <div className={"collapse show"} id="media-collapse">
                            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                <li className="mb-1">
                                    <Link tag="a" className={path === "/files" ? "active" : ""} to="/files" onClick={(e) => this.handleClick(e)}>
                                        Files
                                    </Link>
                                </li>
                            </ul>
                        </div> */}
                    </li> 
                    {/* <li className="mb-1">
                        <Link tag="a" className="" to="/typography">
                            <i className="fa fa-text-width" aria-hidden="true"></i> Typography
                        </Link>
                    </li> */}
                    
                    {/* collapsable list item example */}
                    {categoryMenu}
                    {hiddenMenu}
                </ul>
            </PerfectScrollbar>
            <div className="dropdown fixed-bottom-dropdown">
                <Link to="#" className="d-flex align-items-center text-decoration-none dropdown-toggle" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="/./images/pp.jpg" alt="" width="32" height="32" className="rounded-circle me-2" />
                    <span style={{textTransform:"capitalize"}}>{state.nickname}</span>
                </Link>
                <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
                    <li><Link className="dropdown-item" to="/profile" onClick={(e) => this.handleClick(e)}><i className="fa fa-user-circle" aria-hidden="true"></i> Profile</Link></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><Link className="btn dropdown-item" onClick={(e) => this.handleClick(e)} to="/logout"><i className="fa fa-sign-out" aria-hidden="true"></i> Sign out</Link></li>
                </ul>
            </div>
        </div>
        )
    }
}

export default withRouter(Sidebar);