import React from "react";
import adminLayout from "../hoc/adminLayout";
import Api from '../util/Api'
import withRouter from '../util/withRouter'
import Progress from "./Progress";

class Admin extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            key: 0,
            response: {},
            users: {},
            menu: [],
            userMenu: [],
            userId : localStorage.getItem('userId'),
            role : localStorage.getItem('role'),
            selectedUser: null
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        // this.handleChange = this.handleChange.bind(this);
        // this.getUserMenuData = this.getUserMenuData.bind(this);
        this.getUsersData();
        // this.getMenuData();
    }

    componentDidMount(){
        if(this.state.role !== "1"){
            alert("Halaman tidak di izinkan")
            window.location = "/";
        }
    }
    handleSubmit(e) {
        e.preventDefault();
        if(this.state.selectedUser !== null){
            this.postData();
        }
    }

    postData(){
        let uri = "/user/menu/update";
        const postData = {
            userId: this.state.selectedUser,
            list: this.state.userMenu
        };
        
        Api(uri, postData).then(res => {
            if(res.ec === 0){
                alert("Updated")
            }else{
                alert(res.msg);
            }
        })
        .catch(err => console.log(err));
    }

    getUsersData(){
        let uri = "/user/list";
        const postData = {};
        
        Api(uri, postData).then(res => {
            if(res.ec === 0){
                this.setState({
                    users: res.object
                },() => {});
            }else{
                alert(res.msg);
            }
        })
        .catch(err => console.log(err));
    }

    getMenuData(){
        let uri = "/menu/get/active";
        const postData = {};
        
        Api(uri, postData).then(res => {
            if(res.ec === 0){
                this.setState({
                    menu: res.object
                },() => {});
            }else{
                alert(res.msg);
            }
        })
        .catch(err => console.log(err));
    }

    handleChange = (e) => {
        // e.preventDefault();
        if(e.target.value.length > 0){
            this.setState({
                key: Math.random(),
                selectedUser: e.target.value
            },() => {});
            this.getUserMenuData(e.target.value);
        }else{
            this.setState({
                userMenu: {},
                selectedUser: null
            },() => {});
        }
        // console.log(e.target.value)
    }

    getUserMenuData(userId){
        let uri = "/user/menu/list";
        const postData = {
            userId: userId
        };
        
        Api(uri, postData).then(res => {
            if(res.ec === 0){
                this.setState({
                    userMenu: res.object
                },() => {});
            }else{
                alert(res.msg);
            }
        })
        .catch(err => console.log(err));
    }

    handleCheck = (e) => {
        const key = e.target.id;
        const checked = e.target.checked;
        
        var stateCopy = Object.assign({}, this.state);
        stateCopy.userMenu[key-1].status = checked;
        this.setState(stateCopy);

        // this.state.userMenu[key-1].status = checked;
        // this.forceUpdate();
        e.target.checked=checked
    }

    render(){
        let state = this.state;
        
        const users = state.users.length > 0 ? 
        state.users.map((res,i) => ((
            <option value={res.id} key={i}>{res.nickname}</option>
        )))
        :(<></>);

        // const menu = state.menu.length > 0?
        // state.menu.map((res,i) => ((
        //     <div className="mb-1" key={i}>
        //         <label htmlFor={"checkBox_"+i} className="form-label"><input type={"checkbox"} value={res.id} id={"checkBox_"+i} onChange={(e) => this.handleCheck(e)}  /> {res.name} - {res.id}</label>
        //     </div>
        // )))
        // :(<></>);

        // console.log(state.userMenu[1].id)
        const userMenu = state.userMenu.length > 0 ?
        state.userMenu.map((res,i) => ((
            <div className="mb-1" key={i}>
                <label htmlFor={res.id} className="form-label"><input type={"checkbox"} name="status" id={res.id} value={res.id} checked={res.status} onChange={(e) => this.handleCheck(e)} /> {res.name}</label>
            </div>
        )))
        :(<></>);
        
        return (
            <>
                <div className="row">
                    <div className="col">
                        <h5 className="pb-2 mb-0">User Menu</h5>
                    </div>
                </div>
                <div className="my-3 p-3 bg-body rounded shadow-sm">
                    <h6 className="border-bottom pb-2 mb-0">List</h6>
                    <section id="forms">
                        <article className="my-3" id="overview">
                            <div className="bd-example">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="mb-3">
                                        <label className="form-label">Users</label>                                        
                                        <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example" onChange={(e) => this.handleChange(e)} value={this.value} name="users">
                                            <option value={""}>-Select User-</option>
                                            {users}
                                        </select>
                                    </div>
                                    {/* {userMenu} */}
                                    {/* <div className="mb-3">
                                        {userMenu}
                                    </div> */}
                                    <div className="row">
                                        <div className="col-md-4">{userMenu}</div>
                                        <div className="col-md-8">
                                            {state.selectedUser !== null ? (<Progress user={state.selectedUser} key={this.state.key} />): (<></>)}
                                        </div>
                                    </div>
                                    
                                    <button type="submit" className="btn btn-primary" disabled={state.selectedUser === null ? "disabled":""}>Submit</button>
                                </form>
                            </div>
                        </article>                  
                    </section>
                </div>
            </>
          );
    }
}

export default adminLayout(withRouter(Admin));