import React from "react";
import adminLayout from "../hoc/adminLayout";
import Api from '../util/Api'
import withRouter from '../util/withRouter'
import { Link } from 'react-router-dom';
import dateFormat from "dateformat";
import ModalComponent from "../components/ModalComponent";


class Preview extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            userId : localStorage.getItem('userId'),
            role : localStorage.getItem('role'),
            id : props.router.params.id,
            category : props.router.params.cat,
            title : "",
            titleUrl: "",
            userCreate : "",
            content : "",
            imageUrl : "",
            createdAt : "",
            status : 0,
            note : "",
            noteLength : 200,
            twit : false
        };
        this.getData();
    }

    getData(){
        let uri = "article/"+this.state.category+"/detail/id";
        const postData = this.state;
        Api(uri, postData).then(res => {
            this.setState({
                userCreate: res.userId,
                title: res.title,
                titleUrl: res.titleUrl,
                imageUrl: res.imageUrl,
                content: res.content,
                createdAt: res.createdAt,
                status: res.status
            })
        })
        .catch(err => console.log(err));
    }

    publish(title){
        let uri = "article/"+this.state.category+"/publish";
        const postData = {
            userId: this.state.userId,
            category : this.state.category,
            titleUrl: title
        };
        Api(uri, postData).then(res => {
            if(res.ec === 0){
                alert('Article Published');
                window.location = '/list/'+this.state.category;
            }else{
                alert(res.msg);
            }
        })
        .catch(err => console.log(err));
    }

    reject(title){
        let uri = "article/"+this.state.category+"/reject";
        const postData = {
            userId: this.state.userId,
            category : this.state.category,
            titleUrl: title
        };
        Api(uri, postData).then(res => {
            if(res.ec === 0){
                alert('Article Rejected');
                window.location = '/list/'+this.state.category;
            }else{
                alert(res.msg);
            }
        })
        .catch(err => console.log(err));
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.postData();
    }

    postData(){
        let uri = "/note/create";
        const postData = {
            userIdFrom : this.state.userId,
            userIdTarget : this.state.userCreate,
            value: this.state.note,
            link: "/"+this.state.category+"/"+this.state.id
        };
        
        // console.log(postData)
        Api(uri, postData).then(res => {
            if(res.ec === 0){
                this.setState({
                    response: res
                })
                alert('You send note');
                window.location.reload();
                // window.location = '/list/'+this.state.category;
            }else{
                alert(res.msg);
            }
        })
        .catch(err => console.log(err));
    }
    
    handleNoteChange = (e) => {
        e.preventDefault();
        if(e.target.value.length <= this.state.noteLength){
            this.setState({
                note: e.target.value
            },() => {});
        }
    }

    modalContent = (e) => {
        let charLength = this.state.noteLength - this.state.note.length
        return (
        <>
            <section id="forms">
                <article className="my-3" id="overview">
                    <div className="bd-example">
                        <form onSubmit={this.handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="note" className="form-label">Note</label>
                                <input type="text" value = {this.value} onChange = {this.handleNoteChange} name="note" className="form-control" id="note" aria-describedby="noteHelp" placeholder={"10 - 200 karakter"} maxLength={this.state.noteLength}/>
                                <small>karakter {charLength}</small>
                            </div>
                            <div className="">
                                
                            </div>
                            <div className="mb-3">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </article>
            </section>
        </>);
    }
    render(){
        let state = this.state;
        // let categoryName = state.category.charAt(0).toUpperCase()+state.category.slice(1);
        
        const button = state.role !== "0" && state.status === 2 ? (
            <>
                <div className="row text-right">
                    <div className="col text-right">
                        <button className="m-2 btn btn-info" data-bs-toggle="modal" data-bs-target="#noteModal">Note</button>

                        <button className="btn btn-info" onClick={() => (window.location = "/edit/"+state.category+"/"+state.id)}>Edit</button>
                        
                        <button className="m-2 btn btn-success" onClick={() => this.publish(state.titleUrl)}>Approve</button>
                    
                        <button className="btn btn-danger" onClick={() => this.reject(state.titleUrl)}>Reject</button>

                        <Link className="m-2 btn btn-info" target={"_blank"} to={"https://barbarinfo.com/page/"+state.category+"/"+state.titleUrl+"?to=preview"}>Preview on Site</Link>
                    </div>
                </div>
            </>
        ) : (
        <>
            <div className="row text-right">
                <div className="col text-right">
                    <Link className="m-2 btn btn-info" target={"_blank"} to={"https://barbarinfo.com/page/"+state.category+"/"+state.titleUrl+"?to=preview"}>Preview on Site</Link>
                </div>
            </div>
        </>);
        return (
            <>
                <div className="row">
                    <div className="col text-right">
                        <Link to={"/list/"+state.category}>
                            <button className="btn btn-danger low-height-btn">
                                <i className="fa fa-times"></i>
                            </button>
                        </Link>
                    </div>
                </div>

                {/* <div className="row my-3">
                    <div className="col text-right">
                        {button}
                    </div>
                </div> */}
                <div className="my-3 p-3 bg-body rounded shadow-sm">
                    <div className="row">
                        <div className="col-md-4 col-sm-12 text-left">
                            <h6 className="">Preview</h6>
                        </div>
                        <div className="col-md-8 col-sm-12 text-right">
                            {button}
                        </div>
                    </div>
                    {/* <h6 className="border-bottom pb-2 mb-0">Preview</h6>
                    <div className="col text-right">
                        {button}
                    </div> */}
                    <section id="forms">
                        <article className="my-3" id="overview">
                            <div className="bd-example">
                                <div className="mb-3 text-center">
                                    <h3 className="">{state.title}</h3>
                                    <small>{dateFormat(state.createdAt, "DDD, dd mmm yyyy HH:MM")} WIB</small>
                                </div>
                                <div className="mb-3 text-center">
                                    {state.role !== "0" ? (<div>{state.imageUrl}</div>):(<></>)}
                                    <img src={state.imageUrl === "" ? "/./images/default.jpg" : state.imageUrl} alt='' className='img-responsive img-fluid'/>
                                </div>
                                <div className="mb-3 article-content">
                                    <div dangerouslySetInnerHTML={{__html: state.content}}/>
                                </div>
                            </div>
                        </article>
                        {button}
                    </section>
                </div>
                <ModalComponent title="Note" content={this.modalContent()} dataBsBackdrop="static" id="noteModal"/>
            </>
          );
    }
}

export default adminLayout(withRouter(Preview));