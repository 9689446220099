import React, {useState} from "react";
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";

import ApiIMG from "../util/ApiIMG";
import LoadingSpinner from "../util/LoadingSpinner";




registerPlugin(
    FilePondPluginImagePreview,
    FilePondPluginImageResize,
    FilePondPluginImageEdit,
    FilePondPluginImageTransform,
    FilePondPluginImageExifOrientation
)
function Upload(){
    const [files, initFiles] = useState([])
    const [disabled, setDisabled] = useState(false);

    // const [fileCollection, setFiles] = useState([]);
    // const [isUploaded] = useState(false);

    const onFileChange = async (files) => {
        const items = files.map((fileItem) => fileItem.file);

        // const file = files;
        // const image = await resizeFile(items);
        
        initFiles([...files, items]);
        // console.log(items);

    };
    
    const handleClick = (e) => {
        e.preventDefault();
        setDisabled(true);
        e.currentTarget.disabled = true;
        if(files.length > 0){
            onSubmit(e);
        }else{
            // setDisabled(false)
            alert("Please Browse an Image")
            e.currentTarget.disabled = false;
        }
        
    };

    const onSubmit = (e) => {
        e.preventDefault();
        
        // let responseData = [];
        const formData = new FormData();
        // formData.append('file', files[1][0], files[1][0].name);
        files.map(
            item => item.file
        ).forEach(
            file => formData.append('file', file)
        )
        formData.append('userId', localStorage.getItem("userId"));

        let uri = "/file/upload";
        ApiIMG(uri, formData).then(res => {
            if(res.ec === 0){
                setDisabled(false);
                initFiles([]);
                alert('You have submitted an image');
                window.location = '/files';
            }else{
                setDisabled(false);
                initFiles([]);
                alert(res.msg);
                // window.location = '/files';
            }
        })
        .catch(err => console.log(err));

        // const res = axios.post('http://localhost:9091/file/upload', formData, { headers: { 'Content-Type': 'multipart/form-data'}});
        // const dataPromise = res.then((response) => response.data)
        // responseData = dataPromise;
        // console.log(responseData)
    };

    return (
        <>
        {
            disabled ? 
            (
                <>
                    <LoadingSpinner/>
                </>
            ):
            (
            <div className="z-index-n2">
                <form>
                    <div className="form-group">
                        <button className="btn btn-default" type="submit" onClick={(e) => handleClick(e)}>Upload</button>
                    </div>
                    <div className="filepond-wrapper form-group">
                        <FilePond
                            files={files}
                            server={null}
                            allowMultiple={true}
                            instantUpload={false}
                            maxFiles={5}
                            onupdatefiles={(fileItems) => onFileChange(fileItems)}
                            // onpreparefile={(file, output) => {
                            //   const img = document.createElement("img");
                            //   img.src = URL.createObjectURL(output);
                            //   document.body.appendChild(img);
                            // }}
                            imageResizeTargetWidth={350}
                            imageResizeTargetHeight={200}
                            imageResizeUpscale={false}
                            imageResizeMode={"contain"}

                        />
                        {/* {isUploaded ? (<h3>File has been uploaded</h3>):''} */}
                    </div>
                </form>
            </div>
            )
        }
        </>
    );
}

export default Upload;