import React from "react";
import "../../assets/css/login.css"
import authLayout from "../../hoc/authLayout";
import Api from "../../util/Api";

class LoginPage extends React.Component {
    constructor(props){
        super(props);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            response: [],
            username: "",
            password: "",
            login: localStorage.getItem('login'),
        };
        this.onSubmit = this.onSubmit.bind(this);
        // this.getData = this.getData.bind(this);
    }

    componentDidMount(){
        const { login } = this.state;
        // console.log(login)
        if (login) {
            window.location = '/';
        }
    }

    onSubmit(e) {
        e.preventDefault();
        var user = this.user.value;
        var pass = this.pass.value;

        let uri = "user/login";
        const postData = {
            username: user,
            password: pass
        };
        
        Api(uri, postData).then(res => {
            if(res.ec === 0){
                this.setState({
                    response: res.object
                },() => {});

                localStorage.setItem('login', true);
                localStorage.setItem('userId', res.object.id);
                localStorage.setItem('username', res.object.username);
                localStorage.setItem('nickname', res.object.nickname);
                localStorage.setItem('email', res.object.email);
                localStorage.setItem('role', res.object.role);

                this.getData(localStorage.getItem("userId"));
            }else{
                alert(res.msg)
            }
        })
        .catch(err => console.log(err));
    }
    
    async getData(id){
        let uri = "user/menu/get";
        const postData = {
            userId: id
        };
        
        await Api(uri, postData).then(res => {
            if(res.length > 0){
                localStorage.setItem('access', JSON.stringify(res));
            }
        })
        .catch(err => console.log(err));
        window.location = '/';
    }

    render(){
        return <>
            <form className="login-form" onSubmit={this.onSubmit}>
                <div className="d-flex align-items-center my-4">
                    <h1 className="text-center fw-normal mb-0 me-3">Sign In</h1>
                </div>
                {/* <!-- Email input --> */}
                <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="user">Username</label>
                    <input type="text" id="user" className="form-control form-control-lg"
                    placeholder="Enter a valid username" ref={(c) => this.user = c} name="user" autoComplete="off"/>
                </div>

                {/* <!-- Password input --> */}
                <div className="form-outline mb-3">
                    <label className="form-label" htmlFor="pass">Password</label>
                    <input type="password" id="pass" className="form-control form-control-lg"
                    placeholder="Enter password" ref={(c) => this.pass = c} name="pass"/>
                </div>

                {/* <div className="d-flex justify-content-between align-items-center">
                    <div className="form-check mb-0">
                    <input className="form-check-input me-2" type="checkbox" value="" id="form2Example3" />
                    <label className="form-check-label" htmlFor="form2Example3">
                        Remember me
                    </label>
                    </div>
                    <Link to="/reset-password" className="text-body">Forgot password?</Link>
                </div> */}

                <div className="text-center text-lg-start mt-4 pt-2">
                    <button type="submit" className="btn btn-primary btn-md">Login</button>
                    {/* <p className="small fw-bold mt-2 pt-1 mb-0">Don't have an account? <a href="#!"
                        className="link-danger">Register</a></p> */}
                </div>
            </form>
        </>
    }
}

export default authLayout(LoginPage);