import React from "react";
import adminLayout from "../hoc/adminLayout"
import dateFormat from "dateformat";
import Api from "../util/Api";
import { Link } from "react-router-dom";

class Message extends React.Component {
    constructor(props){
        super(props);

        this.state = {
          response: [],
          userId: localStorage.getItem("userId"),
          limit: 25,
          offset: 0,
        }
        this.getList(this.state.offset)
    }

    getList(newOffset){
      let uri = "note/get";
      const postData = {
        userIdTarget: this.state.userId,
        limit: this.state.limit,
        offset: newOffset,
      }

      Api(uri, postData).then(res => {
        if(res.object.length > 0){
          this.setState({
              response: res.object,
              offset: newOffset
          },() => {});
        }else{
            if(newOffset === this.state.offset){
                this.setState({
                    response: res.object
                },() => {});
            }
        }
      })
      .catch(err => console.log(err));
    }

    nextData(){
      let offset = this.state.offset+this.state.response.length;
      if(this.state.response.length === this.state.limit){
          this.getData(offset);
      }
    }

    prevData(){
        let offset = this.state.offset-this.state.limit;
        if(offset >= 0){
            this.getData(offset);
        }
    }
    render(){
      let state = this.state;
      const data = state.response.length > 0 ? (
        <>
          {state.response.map((res,i) =>  (
              <tr key={i}>
                  <td>
                      <div className="dropdown table-action-dropdown">
                          <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButtonSM" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v" aria-hidden="true"></i></button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtonSM">
                              <li>
                                  <Link to={"/preview"+res.link} className="dropdown-item text-primary">
                                      <i className="fa fa-eye" aria-hidden="true"></i>&nbsp;Preview
                                  </Link>
                              </li>
                              <li>
                                  <Link to={"/edit"+res.link} className="dropdown-item text-dark">
                                      <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Edit
                                  </Link>
                              </li>
                          </ul>
                      </div>
                  </td>
                  <td>
                      <strong>{res.link}</strong>
                  </td>
                  <td>
                      <strong>{res.value}</strong>
                  </td>
                  <td>{dateFormat(res.createdAt, "mmm dd yyyy, HH:MM:ss")}</td>
                  {/* <td>
                      {this.renderStatus(res.status)}
                  </td> */}
              </tr>
          ))}
          </>
      ):(
          <tr>
              <td colSpan={6} className="text-center text-capitalize"><h2>tidak ada data</h2></td>
          </tr>
      )
        return (
        <>
          <h1>Message</h1>
          <div className="d-flex text-muted list-scroll">
              <table className="table">
                  <thead>
                      <tr>
                          <th><cite>#</cite></th>
                          <th><cite>Article</cite></th>
                          <th><cite>Note</cite></th>
                          <th><cite>Date</cite></th>
                      </tr>
                  </thead>
                  <tbody>
                      {data}
                  </tbody>
              </table>
          </div>
        </>
        )
    }
}

export default adminLayout(Message);